<template>
  <div class="operate-container">
    <div class="operate-top container-widths">
      <img
        src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/lou.png"
      />
    </div>
    <div class="operate-hand operate-hand-success">
      <div class="zhong-title con-title">公司简介</div>
      <div class="zhong-text text-con">
        <p class="con-p" style="line-height: 38px">
          郑州智慧园区科技运营有限公司以打造智慧园区，推动中原地区乃至全国范围内的智慧园区行业发展为己任，从事园区智慧化建设及发展规划、园区综合管理平台研发以及智慧园区代运营服务推广工作。
        </p>
        <br />
        <p class="con-p" style="line-height: 38px">
          公司能够为园区的智慧化建设/转型提供规划、监理及指导；通过对智能建筑操作系统在管理中的应用，为物业管理提供高效、可联动、数据化、信息化、智能化的综合物业管理平台；同时，配备专业驻场式互联网运营团队为园区内的企业及个人用户提供符合需求、恰到好处的用户服务。具备全方位开展智慧园区科技运营工作的科研、技术、人员等多方优势。
        </p>
      </div>
      <div class="zhong-img con-img">
        <img
          src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/mengban.png"
        />
      </div>
    </div>
    <div style="width: 100%; background-color: #f2f5f8">
      <div class="operate-bottom container-width">
        <div class="zhong-title con-title">业务架构</div>
        <div class="operate-item">
          <el-container style="display: flex; align-items: center">
            <el-main class="el-pading">
              <div class="title-text" style="margin-bottom: 20px">
                智慧化园区
              </div>
              <div class="text">
                根据每个园区的不同情况，科学决策，进行设施优化、搭建平台、服务优化、管理优化等，从硬体到软体的全面升级，构建园区与用户美好连接，依托人工智能、大数据分析、物联网、机器人等前沿技术连接人、物、服务，帮助园区进行数字化升级，打造智慧化园区。
              </div>
            </el-main>
            <el-aside width="450px">
              <img
                src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/2hao.png"
                style="width: 100%;"
              />
            </el-aside>
          </el-container>
        </div>

        <div class="operate-item">
          <el-container style="display: flex; align-items: center">
            <el-aside width="450px">
              <img
                src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/xiang.png"
                style="width: 100%"
              />
            </el-aside>
            <el-main class="el-pading">
              <div class="title-text" style="margin-bottom: 20px">
                科技化物业
              </div>
              <div class="text">
                提升物业人员专业水平、管理团队管理效率，拓宽业务能力，不仅为园区降低运营成本，还可提高工作效率，同时关注固定资产维护，打造可持续发展的绿色园区，增加管理收益、提升园区各项服务、提升园区资产价值，为园区提供高效的运营方案。
              </div>
            </el-main>
          </el-container>
        </div>

        <div class="operate-item" style="margin: 0">
          <el-container style="display: flex; align-items: center">
            <el-main class="el-pading">
              <div class="title-text" style="margin-bottom: 20px">
                社区化运营
              </div>
              <div class="text">
                棒棒我鸭小程序为园区每位用户提供全方面的基础服务同时，针对不同用户的不同需求，定制人性化、个性化服务，增强与用户的交互沟通，提升用户的参与度和归属感，让每位用户真正成为园区的一份子。
              </div>
            </el-main>
            <el-aside width="450px">
              <img
                src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/shouji.png"
                style="width: 100%"
              />
            </el-aside>
          </el-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.operate-top img {
  width: 100%;
}
.con-img {
}
.el-pading {
  padding: 0 09% 0 90px;
}
.text {
  width: 100%;
}
.operate-bottom {
  background: rgba(242, 245, 248, 1);
  padding-bottom: 40px;
  margin-top: 40px;
}
.operate-item {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  border-radius: 15px;
}
.text {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 44px;
  color: #555;

  opacity: 1;
}
@media screen and (max-width: 1550px) {
  .operate-hand-success {
    padding: 0 60px 0 40px;
  }
  .p {
    padding: 0 40px;
  }
  .container-width {
    padding: 0 80px 40px 60px;
  }
  .title-text {
    margin-left: 0;
  }

}
.container-widths {
    max-width: 1420px !important;
    margin: 0 auto;
  }
</style>
